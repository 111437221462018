import React, { Component } from 'react';
import MenuMember from './MenuMember';
import { connect } from 'react-redux';
import { isAuthUser } from '../react-redux/actions/authAction';
import { logOutUser } from '../react-redux/actions/logOutAction';
import { Link, Navigate } from 'react-router-dom';
import { aLessonContentShow } from '../react-redux/actions/lessonAction';
import withRouter from '../router/withRouter';
import ALessonContentM from '../alphabet/ALessonContentM';

class AlphabetLessonContentM extends Component {
    componentDidMount() {
        this.props.isAuthUser();
        if (this.props.lesson.aLessonContentData === null) {
            this.props.aLessonContentShow()
        }
    }

    logOutButton = () => {
        this.props.logOutUser();
    }

    alphabetLessonContentM = () => {
        const { params } = this.props;
        const { user } = this.props.auth;
        if (this.props.lesson.aLessonContentData !== null) {
            const aLessonContent = this.props.lesson.aLessonContentData.filter(value => value.lesson_id === params.id_lesson);
            return aLessonContent.map(value => {
                return <ALessonContentM key={value.lesson_id + value.type} linkTo={'/seeAlphabetMember/' + params.lesson + '/' + params.id_lesson + '/' + value.type + '/study'} nameContent={value.type} user_id = {user.user_id} lesson_id = {params.id_lesson}/>
            })
        }
    }
    render() {
        const { user } = this.props.auth;
        const { isNavigateLogOut } = this.props.logOut;
        if (isNavigateLogOut) {
            return <Navigate to="/login" />
        }
        return (
            <main>
                <MenuMember fullname={user.fullname} logout={this.logOutButton} />
                <div className="content">
                    <div className="alphbet_lessonContent">
                        <div className="container">
                            <Link to='/seeAlphabetMember'
                                className="btn btn-primary arrowBack"
                            >
                                <i className="fa-solid fa-arrow-left-long" />
                            </Link>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    {this.alphabetLessonContentM()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        logOut: state.logOut,
        lesson: state.lesson
    }
}
const mapDispatchToProps = {
    isAuthUser,
    logOutUser,
    aLessonContentShow
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AlphabetLessonContentM));