import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showReviewCountAll, showReviewCountFive, showReviewCountFour, showReviewCountOne, showReviewCountThree, showReviewCountTwo } from '../react-redux/actions/reviewAction';
import ReviewAllVocabGrammar from './ReviewAllVocabGrammar';

class NoReview extends Component {
    componentDidMount() {
        if (this.props.review.reviewCountAllData === null) {
            this.props.showReviewCountAll();
        }
        if (this.props.review.reviewCountOneData === null) {
            this.props.showReviewCountOne();
        }
        if (this.props.review.reviewCountTwoData === null) {
            this.props.showReviewCountTwo();
        }
        if (this.props.review.reviewCountThreeData === null) {
            this.props.showReviewCountThree();
        }
        if (this.props.review.reviewCountFourData === null) {
            this.props.showReviewCountFour();
        }
        if (this.props.review.reviewCountFiveData === null) {
            this.props.showReviewCountFive();
        }
    }
    showNoReview = (user_id) => {
        if (this.props.review.reviewCountAllData !== null && this.props.review.reviewCountOneData !== null && this.props.review.reviewCountTwoData !== null && this.props.review.reviewCountThreeData !== null && this.props.review.reviewCountFourData !== null && this.props.review.reviewCountFiveData !== null) {
            const findUserReviewCountAll = this.props.review.reviewCountAllData.filter(value => value.user_id === user_id);
            const findUserReviewCountOne = this.props.review.reviewCountOneData.filter(value => value.user_id === user_id);
            const findUserReviewCountTwo = this.props.review.reviewCountTwoData.filter(value => value.user_id === user_id);
            const findUserReviewCountThree = this.props.review.reviewCountThreeData.filter(value => value.user_id === user_id);
            const findUserReviewCountFour = this.props.review.reviewCountFourData.filter(value => value.user_id === user_id);
            const findUserReviewCountFive = this.props.review.reviewCountFiveData.filter(value => value.user_id === user_id);
            if (findUserReviewCountAll.length > 0) {
                // zero
                if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }
                //one
                else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }
                //two 
                else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }
                //three
                else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }
                //four
                else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {findUserReviewCountFive[0].countvocabfive} countGrammarFive = {findUserReviewCountFive[0].countgrammarfive} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {findUserReviewCountFour[0].countvocabfour} countGrammarFour = {findUserReviewCountFour[0].countgrammarfour} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {0} countVocabThree = {findUserReviewCountThree[0].countvocabthree} countGrammarThree = {findUserReviewCountThree[0].countgrammarthree} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {0} countGrammarOne = {0} countVocabTwo = {findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo = {findUserReviewCountTwo[0].countgrammartwo} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0 ){
                    return (
                        <ReviewAllVocabGrammar countVocabAll= {findUserReviewCountAll[0].countvocaball} countGrammarAll = {findUserReviewCountAll[0].countgrammarall} countVocabOne = {findUserReviewCountOne[0].countvocabone} countGrammarOne = {findUserReviewCountOne[0].countgrammarone} countVocabTwo = {0} countGrammarTwo = {0} countVocabThree = {0} countGrammarThree = {0} countVocabFour = {0} countGrammarFour = {0} countVocabFive = {0} countGrammarFive = {0} clickNoReview = {this.props.clickNoReview}/>
                    )
                }
            }else{
                return (
                    <div className="reviewAllVocabGrammar noVocabGrammar">
                    <div className="vocabulary">
                        <h3>
                            Hiện tại chưa có từ vựng để <br />
                            ôn tập
                        </h3>
                        <button type="button" className="btn btn-primary">
                            Từ vựng
                        </button>
                    </div>
                    <div className="grammar">
                        <h3>
                            Hiện tại chưa có ngữ pháp để <br /> ôn tập
                        </h3>
                        <button type="button" className="btn btn-primary">
                            Ngữ pháp
                        </button>
                    </div>
                </div>
                )
            }
        }
    }

    render() {
        const { user } = this.props.auth;
        return (
            <div className="content">
                {this.showNoReview(user.user_id)}
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        review: state.review
    }
}
const mapDispatchToProps = {
    showReviewCountAll,
    showReviewCountOne,
    showReviewCountTwo,
    showReviewCountThree,
    showReviewCountFour,
    showReviewCountFive
}
export default connect(mapStateToProps, mapDispatchToProps)(NoReview)