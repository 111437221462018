import React, { Component } from "react";

class AudioRecorder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRecording: false,
            audioURL: null
        };
        this.mediaRecorder = null;
        this.audioChunks = [];
    }

    startRecording = async () => {
        this.setState({ isRecording: true });

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.ondataavailable = (event) => {
            this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/mp3' });
            const audioURL = URL.createObjectURL(audioBlob);
            this.setState({ audioURL });
            this.audioChunks = [];
        };

        this.mediaRecorder.start();
    };

    stopRecording = () => {
        this.setState({ isRecording: false });
        this.mediaRecorder.stop();
    };

    render() {
        const { isRecording, audioURL } = this.state;

        return (
            <div>
                <button onClick={isRecording ? this.stopRecording : this.startRecording} className="recorderAudiobutton">
                    {isRecording ? <i className="fa-solid fa-microphone-slash"></i> : <i className="fa-solid fa-microphone"></i>}
                </button>

                {audioURL && (
                    <div className="audioRecorder">
                        <p>Nội dung đoạn ghi âm:</p>
                        <audio src={audioURL} controls/>
                        <a href={audioURL} download="recorded_audio.mp3" className="audioURLChatBot">
                            <i className="fa-solid fa-download"></i>Lưu ghi âm
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

export default AudioRecorder;
