import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isAuthUser } from '../react-redux/actions/authAction';
import { logOutUser } from '../react-redux/actions/logOutAction';
import { Navigate } from 'react-router-dom';
import NavbarAdmin from './component/NavbarAdmin';
import MenuAdmin from './component/MenuAdmin';
import TableManagerTimeStudy from './component/TableManagerTimeStudy';
import { timeStudy } from '../react-redux/actions/signUpMemberAction';
import ModelEditTimeStudy from './component/ModelEditTimeStudy';
import { editTimeStudy } from '../react-redux/actions/adminAction';

class ManagerTimeStudy extends Component {

    componentDidMount() {
        this.props.isAuthUser();
        if (this.props.signUpMember.timeStudyData === null) {
            this.props.timeStudy()
        }
    }

    componentDidUpdate() {
        var iconBar = document.querySelector('.fa-bars');
        var menu = document.querySelector('.menuAll')
        iconBar.onclick = function () {
            menu.classList.toggle('hiddenMenu');
        }
    }

    logOutButton = () => {
        this.props.logOutUser();
    }

    constructor(props) {
        super(props);
        this.state = {
            timestudy_name: '',
            original_price: '',
            discount_percen: '',
            timestudy_id: null
        }
    }


    managerTimeStudy = () => {
        if (this.props.signUpMember.timeStudyData !== null) {
            return this.props.signUpMember.timeStudyData.map((value) => {
                return <TableManagerTimeStudy key={value.original_price} stt={value.timestudy_id} timestudy_name={value.timestudy_name} original_price={value.original_price} discount_percen={value.discount_percen} clickIconEdit={() => this.clickIconEdit(value)} />
            });
        }
    }

    clickIconEdit = (timestudy) => {
        this.setState({
            timestudy_name: timestudy.timestudy_name,
            original_price: timestudy.original_price,
            discount_percen: timestudy.discount_percen,
            timestudy_id: timestudy.timestudy_id
        });
    }

    isChangeEdit = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    clickEditTimeStudy = () => {
        const { timestudy_id, timestudy_name, original_price, discount_percen } = this.state;
        const timeAndPriceStudy = {timestudy_name, original_price, discount_percen}
        if (timestudy_id) {
            this.props.editTimeStudy(timestudy_id,timeAndPriceStudy);
            this.setState({
                timestudy_name: '',
                original_price: '',
                discount_percen: '',
                timestudy_id: null
            });
        }
    }

    render() {
        const { user } = this.props.auth;
        const { isNavigateLogOut } = this.props.logOut;
        if (isNavigateLogOut) {
            return <Navigate to="/login" />
        }
        return (
            <div className="admin" style={{ height: "100%", overflowY: "hidden" }}>
                <NavbarAdmin fullname={user.fullname} logout={this.logOutButton} />
                <main style={{ height: "100%", overflowY: "hidden" }}>
                    <div className="container-fluid" style={{ height: "100%", overflowY: "hidden" }}>
                        <div className="row" style={{ height: "100%", overflowY: "hidden" }}>
                            <div className="col-xs-6 col-sm-3 col-md-3 col-xl-3 menuAll">
                                <MenuAdmin />
                            </div>
                            {/* end menuAll  */}
                            <div className="col">
                                <div className="managerCourse">
                                    <h2 className="tittle">Quản lý thời gian học</h2>
                                    {/* table */}
                                    <div className="tableManagerUser">
                                        <div className="table-responsive-xl">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="col-xl-1" scope="col">
                                                            STT
                                                        </th>
                                                        <th className="col-xl-3" scope="col">
                                                            Thời gian học
                                                        </th>
                                                        <th className="col-xl-3" scope="col">
                                                            Giá gốc
                                                        </th>
                                                        <th className="col-xl-3" scope="col">
                                                            Phần trăm giảm
                                                        </th>
                                                        <th className="col-xl-3" scope="col">
                                                            Giá đã giảm
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.managerTimeStudy()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <ModelEditTimeStudy onChange={(event) => this.isChangeEdit(event)} timestudy_id={this.state.timestudy_id} timestudy_name={this.state.timestudy_name} original_price={this.state.original_price} discount_percen={this.state.discount_percen} clickEditTimeStudy={this.clickEditTimeStudy} />
                                </div>
                                {/* end managerCourse */}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        logOut: state.logOut,
        admin: state.admin,
        signUpMember: state.signUpMember
    }
}
const mapDispatchToProps = {
    isAuthUser,
    logOutUser,
    timeStudy,
    editTimeStudy
}
export default connect(mapStateToProps, mapDispatchToProps)(ManagerTimeStudy);