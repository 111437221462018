import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { updateReview } from '../react-redux/actions/reviewAction';

class ReviewSuccessG extends Component {
    clickUpdateGrammar = () => {
        this.props.updateReview(this.props.completedReview);
        return <Navigate to="/homeMember" />
    }
    render() {
        return (
            <div className='studySuccessG'>
                <h1 className="h2">ÔN TẬP THÀNH CÔNG</h1>
                <div className="listGrammarAll">
                    <ul className="list">
                        <p className='completeAndCorrect'>Ngữ pháp ôn tập thành công</p>
                        {this.props.completedReview.map(value =>
                            <li key={value.grammar_id}>
                                <p className="name">{value.name + ' : '}</p>
                                <p className="mean">{value.mean}</p>
                            </li>
                        )}
                    </ul>
                    <ul className="list">
                        <p className='completeAndCorrect'>Từ vựng chưa hoàn thành.<br></br> Vui lòng ôn tập lại.</p>
                        {this.props.incorrectThree.map(value =>
                            <li key={value.grammar_id}>
                                <p className="name">{value.name}</p>
                                <p className="mean">{value.mean}</p>
                            </li>
                        )}
                    </ul>
                </div>
                <Link to={this.props.linkto}
                    className="btn btn-primary btnGrammarStudy"
                    onClick={this.clickUpdateGrammar()}
                >
                    Tiếp tục
                </Link>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        review: state.review
    }
}
const mapDispatchToProps = {
    updateReview
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewSuccessG)