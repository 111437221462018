import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { vocabularyShow } from '../react-redux/actions/studyAction';

class LevelVocab extends Component {
    componentDidMount() {
        if (this.props.study.vocabularyData === null) {
            this.props.vocabularyShow();
        }
    }
    showLevel = () => {
        if (this.props.study.vocabularyData !== null) {
            const findCountVocab = this.props.study.vocabularyData.filter(value => value.level_id === this.props.levelID)
            return (
                <Link to={this.props.see + this.props.content + "/" + this.props.levelID}
                    name=""
                    id=""
                    className="btn btn-primary"
                    role="button"
                >
                    <img
                        src="/assets/image/level_vocab.png"
                        className="img-fluid rounded-top"
                        alt=""
                    />
                    <p>{this.props.content}</p>
                    <h5>{findCountVocab.length}言葉</h5>
                </Link>
            )
        }
    }
    render() {
        return (
            <div className="seelevel">
                {this.showLevel()}
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        study: state.study
    }
}
const mapDispatchToProps =  {
    vocabularyShow
}
export default connect(mapStateToProps, mapDispatchToProps)(LevelVocab);