import axios from 'axios';
import { alertDOnSuccess, alertSOnSuccess } from './alertAction';

export const SIGN_UP_MEMBER = 'SIGN_UP_MEMBER';
const signUpMemberSuccess = (data) => ({
    type: SIGN_UP_MEMBER,
    signUpMemberAction:data
})
export const signUpMemberUser = (formData) => {
    return async (dispatch) => {
        try {
            const response = await axios.post('/signupmember',formData,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
            });
            dispatch(signUpMemberSuccess(response.data));
            dispatch(alertSOnSuccess())
        } catch (error) {
            console.error("Lỗi");
            dispatch(alertDOnSuccess())
        }
    }
}
//timeStudy
export const TIME_STUDY = 'TIMESTUDY';
const timeStudySuccess = (data) => ({
    type:TIME_STUDY,
    timeStudyAction:data
})
export const timeStudy = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/signupmember/timestudy');
            dispatch(timeStudySuccess(response.data));
        } catch (error) {
            console.error("lấy dữ liệu lỗi");
        }
    }
}