import React, { Component } from 'react';

class HaveDiscountPercent extends Component {
    // Hàm tính giá sau khi giảm
  calculateDiscountedPrice = () => {
    const { original_price, discount_percen } = this.props;
    return original_price - (original_price * discount_percen / 100);
  };
    render() {
        const discountedPrice = this.calculateDiscountedPrice();
        const original_price = Number(this.props.original_price)
        return (
            <li className='time_study'>
                <p className='name_price'>{this.props.timestudy_name}: </p>
                <div className="allprice">
                    <p className='discount'> {discountedPrice.toLocaleString('vi-VN')+' VNĐ'}</p>
                    <p className='original_price'>{original_price.toLocaleString('vi-VN')+' VNĐ'}</p>
                    <p className='discountPercentage'>{this.props.discount_percen}%</p>
                </div>
            </li>
        );
    }
}

export default HaveDiscountPercent;