import React, { Component } from 'react';
import MenuIndex from './MenuIndex';
import InstructionNotice from '../alerts/InstructionNotice';
class Review extends Component {
    render() {
        return (
            <main>
                <MenuIndex />
                <div className="content">
                    <div className='background_second'></div>
                    <div className="reviewAllVocabGrammar noVocabGrammar">
                        <div className="vocabulary">
                            <h3>
                                Hiện tại chưa có <br />từ vựng 
                                ôn tập
                            </h3>
                            <button type="button" className="btn btn-primary">
                                Từ vựng
                            </button>
                        </div>
                        <div className="grammar">
                            <h3>
                                Hiện tại chưa có <br />ngữ pháp ôn tập
                            </h3>
                            <button type="button" className="btn btn-primary">
                                Ngữ pháp
                            </button>
                        </div>
                        <InstructionNotice/>
                    </div>
                </div>
            </main>
        );
    }
}

export default Review;