import React, { Component } from 'react';

class TableManagerTimeStudy extends Component {
    calculateDiscountedPrice = () => {
        const { original_price, discount_percen } = this.props;
        return original_price - (original_price * discount_percen / 100);
    };
    render() {
        const discountedPrice = this.calculateDiscountedPrice();
        const original_price = Number(this.props.original_price)
        return (
            <tr>
                <td>{this.props.stt}</td>
                <td>{this.props.timestudy_name}</td>
                <td>{original_price.toLocaleString('vi-VN')+' VNĐ'}</td>
                <td>
                    {this.props.discount_percen+'%'}
                </td>
                <td>
                    {discountedPrice.toLocaleString('vi-VN')+' VNĐ'}
                </td>
                <td className="action">
                    <i
                        className="fa-solid fa-user-pen"
                        data-bs-toggle="modal"
                        data-bs-target="#timeStudyEdit"
                        onClick={this.props.clickIconEdit}
                    />
                </td>
            </tr>
        );
    }
}

export default TableManagerTimeStudy;