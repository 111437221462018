import React, { Component } from 'react';

class AlertSuccessStudy extends Component {
    clickTranslate = () => {
        const translateclick = document.querySelector('i.fa-solid.fa-language');
        const example_mean_show = document.querySelector('.example_mean');
        translateclick.onclick = function () {
            example_mean_show.classList.toggle('show');
        }
    }

    clickIconSound = () => {
        var clickIcon = document.querySelector('.fa-solid.fa-volume-high');
        // console.log(clickIcon);
        var soundCard = document.querySelector('.audios');
        clickIcon.onclick = function () {
            soundCard.play();
            this.classList.toggle('scale');
            this.addEventListener("webkitAnimationEnd", function () {
                this.classList.remove('scale')
            })
        }
    }

    render() {
        return (
            <div className="alerts alertSuccess">
                <h2>Đáp án đúng</h2>
                <div className="alert">
                    <div className="contentAlert">
                        <div className="soundNamePronunciationAll">
                            <div className="sound_listen_alert">
                                <audio className="audios" autoPlay>
                                    <source src={this.props.sound} type="audio/mpeg" />
                                </audio>
                                <i className="fa-solid fa-volume-high" onClick={() => this.clickIconSound()} />
                            </div>
                            <p>{this.props.name + '(' + this.props.pronunciation + ') : ' + this.props.mean}</p>
                        </div>
                        <div className="meanExampleAll">
                            <i className="fa-solid fa-language" onClick={this.clickTranslate}></i>
                            <p>{this.props.example}</p>
                        </div>
                        <p className="example_mean">{this.props.example_mean}</p>
                    </div>
                    <button
                        name=""
                        id=""
                        className="btn btn-primary continue"
                        onClick={this.props.howtolearnNext}
                    >
                        Tiếp tục
                    </button>
                </div>
            </div>
        );
    }
}

export default AlertSuccessStudy;