import React, { Component } from 'react';

class NoHaveDiscountPercent extends Component {
    render() {
        const original_price = Number(this.props.original_price)
        return (
            <li className='priceNoPercent'>    
                <p>{this.props.timestudy_name +': '}</p>
                <p className='original_price_NoPercent'>{original_price.toLocaleString('vi-VN')}</p>
                <p className='vnđ'>VNĐ</p> 
            </li>
        );
    }
}

export default NoHaveDiscountPercent;