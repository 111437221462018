import React, { Component } from 'react';
import { connect } from 'react-redux';
import AlertSuccessStudy from '../vocabulary/AlertSuccessStudy';
import AlertErrorStudy from '../vocabulary/AlertErrorStudy';
import { Link } from 'react-router-dom';
import TinySegmenter from 'tiny-segmenter';
import axios from 'axios';

class ViewAndChooseMean extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectOption: '',
            optionValue: this.takeOptions(),
            showAlertStudy: '',
            isDisabled: false,
            exampleAnalysis: this.props.example,
            resultExampleAnalysis: [],
        }
    }

    handleExampleAnalysis = async () => {
        try {
            const response = await axios.get('/review/exampleAnalysis', {
                params: { exampleAnalysis: this.state.exampleAnalysis },
            });

            this.setState({ resultExampleAnalysis: response.data });
        } catch (error) {
            console.error('Lỗi khi gọi API:', error);
        }
    };

    componentDidMount() {
        if (this.state.exampleAnalysis) {
            this.handleExampleAnalysis();
        }
    }

    clickIconSound = () => {
        var clickIcon = document.querySelector('.fa-solid.fa-volume-high');
        // console.log(clickIcon);
        var soundCard = document.querySelector('.audios');
        clickIcon.onclick = function () {
            soundCard.play();
            this.classList.toggle('scale');
            this.addEventListener("webkitAnimationEnd", function () {
                this.classList.remove('scale')
            })
        }
    }
    takeOptions = () => {
        if (this.props.study.vocabularyData !== null) {
            const { vocabularyData } = this.props.study;
            const correctOptions = this.props.vocab_id;
            const otherOptions = vocabularyData.map(value => value.vocab_id).filter(vocab_id => vocab_id !== correctOptions);
            const randomOptions = this.shuffle(otherOptions).slice(0, 3);
            const allOptions = this.shuffle([...randomOptions, correctOptions]);
            return allOptions;
        }
    }
    shuffle = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    clickOptionChoose = (option) => {
        var optionChoose = document.querySelectorAll('.optionChoose')
        for (var i = 0; i < optionChoose.length; i++) {
            optionChoose[i].onclick = function () {
                for (let a = 0; a < optionChoose.length; a++) {
                    optionChoose[a].classList.remove('optionChooseColor')

                }
                this.classList.add('optionChooseColor')
            }
        }
        this.setState({
            selectOption: option
        });
    }

    clickCheck = () => {
        const correctOptions = this.props.vocab_id;
        const { selectOption } = this.state;
        if (correctOptions === selectOption) {
            this.setState({
                showAlertStudy: 'success',
                isDisabled: true
            });
        } else {
            this.setState({
                showAlertStudy: 'error',
                isDisabled: true
            });
        }
    }
       
    render() {
        // console.log(this.state.exampleAnalysis);
        // console.log(this.state.resultExampleAnalysis);
        const { vocabularyData } = this.props.study;
        const { resultExampleAnalysis } = this.state;
        const { name } = this.props;
        const segmenter = new TinySegmenter();
        const nameSegments = segmenter.segment(this.props.name);
        const exampleVocabArrayHighlighted = (resultExampleAnalysis, answer, nameSegments) => {
            let highlightedSentence = []; // Mảng chứa các token sau khi xử lý
            let combinedBasicForm = ""; // Gộp các `basic_form`
            let combinedSurfaceForm = ""; // Gộp các `surface_form`
            let tokensToSkip = 0; // Số lượng token cần bỏ qua sau khi gộp
            let isMatched = false; // Đánh dấu xem có khớp với answer hay không
        
            for (let i = 0; i < resultExampleAnalysis.length; i++) {
                // Bỏ qua token đã được sử dụng trong lần gộp trước
                if (tokensToSkip > 0) {
                    tokensToSkip--;
                    continue;
                }
        
                const token = resultExampleAnalysis[i];
        
                // Nếu `answer` chỉ có 1 ký tự, kiểm tra từng ký tự trong `surface_form`
                if (answer.length === 1) {
                    const splitToken = token.surface_form.split(""); // Tách từng ký tự
                    splitToken.forEach((char, charIndex) => {
                        highlightedSentence.push(
                            char === answer ? (
                                <span
                                    key={`char-match-${i}-${charIndex}`}
                                    style={{
                                        color: "#ff0000e0",
                                        fontWeight: "400",
                                    }}
                                >
                                    {char}
                                </span>
                            ) : (
                                <span key={`char-${i}-${charIndex}`}>{char}</span>
                            )
                        );
                    });
                    continue; // Chuyển sang token tiếp theo
                }
        
                // Gộp các token cho đến khi khớp với 'answer'
                combinedBasicForm = token.basic_form;
                combinedSurfaceForm = token.surface_form;
                let tempTokens = [token.surface_form]; // Lưu các surface_form để render
        
                let j = i + 1; // Bắt đầu từ token tiếp theo
                while (
                    j < resultExampleAnalysis.length &&
                    combinedBasicForm !== answer &&
                    combinedSurfaceForm !== answer
                ) {
                    combinedBasicForm += resultExampleAnalysis[j].basic_form; // Gộp basic_form
                    combinedSurfaceForm += resultExampleAnalysis[j].surface_form; // Gộp surface_form
                    tempTokens.push(resultExampleAnalysis[j].surface_form); // Gộp surface_form
                    j++;
                }
        
                // Nếu khớp với đáp án
                if (combinedBasicForm === answer || combinedSurfaceForm === answer) {
                    isMatched = true;
                    tokensToSkip = tempTokens.length - 1; // Bỏ qua các token đã gộp
        
                    // Render các token đã khớp
                    highlightedSentence.push(
                        <span
                            key={`match-${i}`}
                            style={{
                                color: "#ff0000e0",
                                fontWeight: "400",
                            }}
                        >
                            {tempTokens.join("")}
                        </span>
                    );
                } else {
                    // Nếu không khớp, render token hiện tại
                    highlightedSentence.push(
                        <span key={`normal-${i}`}>{token.surface_form}</span>
                    );
                }
            }
        
            return highlightedSentence;
        };        

        const meanOptionById = (id) => {
            const meanOption = vocabularyData.find(value => value.vocab_id === id);
            return meanOption ? (meanOption.example_mean + ' / ' + meanOption.sino_vietnamese_sound) : '';
        }
        return (
            <div className="listenAndChoose ViewAndChooseMean">
                <h2>Hãy xem ví dụ và chọn đáp án </h2>
                <div className="viewVocabExample">
                    {exampleVocabArrayHighlighted(resultExampleAnalysis, name, nameSegments)}
                </div>
                <div className="optionChooseALL">
                    {Array.isArray(this.state.optionValue) ?
                        (this.state.optionValue.map(value => (
                            <button key={value} type="button" className="btn btn-primary optionChoose" onClick={() => this.clickOptionChoose(value)}>
                                {meanOptionById(value)}
                            </button>
                        )))
                        : (
                            <Link to='/homeMember'>Vui lòng chọn vào đây để tiến hành ôn tập lại</Link>
                        )
                    }
                </div>
                <button name="" id="" className="btn btn-primary check" onClick={this.clickCheck} disabled={this.state.isDisabled}>
                    kiểm tra
                </button>
                {this.state.showAlertStudy === 'success' && <AlertSuccessStudy name={this.props.name} pronunciation={this.props.pronunciation} mean={this.props.mean} example={this.props.example} example_mean={this.props.example_mean} sound={this.props.sound} howtolearnNext={this.props.howToLearnNext} />}
                {this.state.showAlertStudy === 'error' && <AlertErrorStudy name={this.props.name} pronunciation={this.props.pronunciation} mean={this.props.mean} example={this.props.example} example_mean={this.props.example_mean} sound={this.props.sound} howToLearnBack={this.props.howToLearnBackWrite} nameButton={'Quay lại'} />}
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        study: state.study
    }
}
export default connect(mapStateToProps)(ViewAndChooseMean);