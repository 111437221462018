import React, { Component } from 'react';

class ModelEditTimeStudy extends Component {
    showButtonDisableOrNODisable = () => {
        if (this.props.timestudy_name === '' || this.props.original_price === '' || this.props.discount_percen === '') {
            return (
                <button type="button" className="btn btn-primary" disabled>
                    Sửa
                </button>
            );
        } else {
            return (
                <button type="button" className="btn btn-primary" onClick={this.props.clickEditTimeStudy}>
                    Sửa
                </button>
            )
        }
    }
    render() {
        return (
            <div className="modal" tabIndex={-1} id="timeStudyEdit">
                <div className="modal-dialog  modal-md modal-dialog-centered">
                    {
                        this.props.timestudy_id && (
                            <form className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Sửa thời gian học</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body">
                                    <div className="mb-2">
                                        <label className="form-label">Thời gian học</label>
                                        <input
                                            type="text"
                                            name="timestudy_name"
                                            id=""
                                            value={this.props.timestudy_name}
                                            className="form-control"
                                            placeholder="Nhập thời gian học"
                                            aria-describedby="helpId"
                                            onChange={this.props.onChange}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Giá gốc</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="original_price"
                                            id=""
                                            value={this.props.original_price}
                                            aria-describedby="helpId"
                                            placeholder="Nhập giá gốc"
                                            onChange={this.props.onChange}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Phần trăm giảm giá</label>
                                        <input
                                            type="text"
                                            name="discount_percen"
                                            id=""
                                            value={this.props.discount_percen}
                                            className="form-control"
                                            placeholder="Nhâp phần trăm giảm giá"
                                            aria-describedby="helpId"
                                            onChange={this.props.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {this.showButtonDisableOrNODisable()}
                                </div>
                            </form>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default ModelEditTimeStudy;