import React, { Component } from 'react';
import MenuMember from './MenuMember';
import { connect } from 'react-redux';
import { isAuthUser } from '../react-redux/actions/authAction';
import { logOutUser } from '../react-redux/actions/logOutAction';
import { vocabLessonShow } from '../react-redux/actions/lessonAction';
import LessonVocabMember from '../lesson/LessonVocabMember';
import withRouter from '../router/withRouter';
import { Navigate } from 'react-router-dom';
import NoLesson from '../lesson/NoLesson';

class SeeVocabLessonM extends Component {
    componentDidMount() {
        this.props.isAuthUser();
        if(this.props.lesson.vocabLessonData === null) {
            this.props.vocabLessonShow()
        }
    }

    logOutButton = () => {
        this.props.logOutUser();
    }

    showLesson = () => {
        const {params} = this.props;
        const {user} = this.props.auth;
        if(this.props.lesson.vocabLessonData !== null ) {
            const lessonVocab =  this.props.lesson.vocabLessonData.filter(value => value.level_id === params.id_level );
            if(lessonVocab.length > 0){
                return lessonVocab.map((value) => {
                    return  <LessonVocabMember key={value.level_id} user_id={user.user_id} lesson = {value.lesson_name}
                    linkto = {'/seeVocabMember/' + params.level + '/' + params.id_level + '/' + value.lesson_name}/>
                })
            }else{
                return (
                    <NoLesson linkto = '/seeVocabMember'/>
                )
            }
        }
    }

    render() {
        const {user} = this.props.auth;
        const {isNavigateLogOut} = this.props.logOut;
        if (isNavigateLogOut) {
            return <Navigate to="/login" />
        }
        return (
            <main>
                <MenuMember fullname={user.fullname} logout={this.logOutButton}/>
                <div className="content">
                    <div className="see_vocab_lesson container">
                        <div className="row">
                            {this.showLesson()}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        logOut:state.logOut,
        lesson:state.lesson
    }
}
const mapDispatchToProps = {
    isAuthUser,
    logOutUser,
    vocabLessonShow
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SeeVocabLessonM));