import React, { Component } from 'react';

class DrawAlphabet extends Component {
    componentDidUpdate() {
        const canvas = document.getElementById('drawingCanvas');
        const ctx = canvas.getContext('2d');
        ctx.lineCap = 'round'; // Đầu bút tròn
        ctx.lineJoin = 'round'; // Nét mềm
        ctx.lineWidth = 3; // Độ dày bút, có thể điều chỉnh

        let isDrawing = false;
        let currentPath = [];
        let allPaths = [];

        // Hàm lấy vị trí chính xác của sự kiện vẽ (chuột hoặc cảm ứng)
        function getPointerPosition(event) {
            const rect = canvas.getBoundingClientRect();
            if (event.touches) {
                return {
                    x: event.touches[0].clientX - rect.left,
                    y: event.touches[0].clientY - rect.top
                };
            } else {
                return {
                    x: event.clientX - rect.left,
                    y: event.clientY - rect.top
                };
            }
        }

        // Hàm vẽ đường nét Kanji
        function drawLine(points) {
            ctx.beginPath();
            ctx.moveTo(points[0].x, points[0].y);

            for (let i = 1; i < points.length - 2; i++) {
                const c = (points[i].x + points[i + 1].x) / 2;
                const d = (points[i].y + points[i + 1].y) / 2;
                ctx.quadraticCurveTo(points[i].x, points[i].y, c, d);
            }

            ctx.quadraticCurveTo(
                points[points.length - 2].x,
                points[points.length - 2].y,
                points[points.length - 1].x,
                points[points.length - 1].y
            );

            ctx.stroke();
        }

        function redrawAll() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            allPaths.forEach(path => drawLine(path));
        }

        canvas.addEventListener('mousedown', (e) => {
            isDrawing = true;
            const pos = getPointerPosition(e);
            currentPath = [pos];
        });

        canvas.addEventListener('mousemove', (e) => {
            if (!isDrawing) return;

            const pos = getPointerPosition(e);
            currentPath.push(pos);
            redrawAll();
            drawLine(currentPath);
        });

        canvas.addEventListener('mouseup', () => {
            if (isDrawing) {
                isDrawing = false;
                allPaths.push([...currentPath]);
            }
        });

        canvas.addEventListener('mouseleave', () => {
            if (isDrawing) {
                isDrawing = false;
                allPaths.push([...currentPath]);
            }
        });

        // Hỗ trợ cảm ứng cho thiết bị di động
        canvas.addEventListener('touchstart', (e) => {
            e.preventDefault();
            isDrawing = true;
            const pos = getPointerPosition(e);
            currentPath = [pos];
        });

        canvas.addEventListener('touchmove', (e) => {
            e.preventDefault();
            if (!isDrawing) return;

            const pos = getPointerPosition(e);
            currentPath.push(pos);
            redrawAll();
            drawLine(currentPath);
        });

        canvas.addEventListener('touchend', () => {
            if (isDrawing) {
                isDrawing = false;
                allPaths.push([...currentPath]);
            }
        });

        // Nút "Xóa"
        document.getElementById('clearButton').addEventListener('click', () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            allPaths = [];
        });
    }
    
    render() {
        return (
            <div className="draw-alphabet">
                <h1>Luyện tập viết</h1>
                <canvas id="drawingCanvas"></canvas>
                <i className="fa-solid fa-delete-left" id="clearButton"></i>
            </div>
        );
    }
}

export default DrawAlphabet;